<template>
    <div class="map-trace">
      <Header v-if="false" />
  
      <!-- 地图 -->
      <div class="map-container" id="mapContainer">
        
      </div>

      <div class="order-info" id="orderInfo" v-if="orderInfoFlag">

        <div class="title">承运人信息</div>
        <div class="card-wrap">
          <div class="divider"></div>
          <div class="content-item flex-50">
            <span class="label">承运商：</span>
            <span class="value">{{orderLogistics.organName}}</span>
          </div>
          <div class="content-item flex-50">
            <span class="label">车牌号：</span>
            <span class="value">{{orderLogistics.carNumber}}</span>
          </div>
          <div class="content-item flex-50">
            <span class="label">承运人：</span>
            <span class="value">{{orderLogistics.driverName}}</span>
          </div>
          <div class="content-item flex-50">
            <span class="label">电话：</span>
            <span class="value" style="color: #0014b7" @click="callPhone(orderLogistics.driverPhone)">{{orderLogistics.driverPhone}}</span>
          </div>
          <div class="content-item ">
            <span class="label">出厂时间：</span>
            <span class="value">{{orderLogistics.outTime}}</span>
          </div>
          <div class="content-item ">
            <span class="label">签收验证码：</span>
            <span class="value">{{orderLogistics.signCode}}</span>
          </div>
          <slot></slot>
        </div>

        <div class="title">产品信息</div>
        <div class="card-wrap" v-for="(item, index) in details" :key="index" :style="index===details.length-1?'padding-bottom: 40px;':''">
          <div class="content-item">
            <span style="color: #2c2c2c;font-size: 18px;padding-left: 10px;">{{item.materialName}}</span>
          </div>
          <div class="content-item flex-50">
            <span class="label">包重：</span>
            <span class="value">{{item.bagWeight}}</span>
          </div>
          <div class="content-item flex-50">
            <span class="label">申请数量：</span>
            <span class="value">{{item.applyNumber}}</span>
          </div>

          <div class="content-item flex-50">
            <span class="label">已发数量：</span>
            <span class="value">{{item.sendNumber}}</span>
          </div>
          <div class="content-item flex-50">
            <span class="label">配比：</span>
            <span class="value">{{item.matchEq}}</span>
          </div>

          <div class="content-item flex-50">
            <span class="label">运费：</span>
            <span class="value">{{item.tranAllprice}}</span>
          </div>
          <div class="content-item flex-50">
            <span class="label">物料分类：</span>
            <span class="value">{{item.loggr}}</span>
          </div>

          <div class="content-item flex-50">
            <span class="label">单价：</span>
            <span class="value">{{item.materialPrice}}</span>
          </div>
          <div class="content-item flex-50">
            <span class="label">耗用返利(元/吨)：</span>
            <span class="value">{{item.zhPrice}}</span>
          </div>

          <div class="content-item flex-50">
            <span class="label">耗用返利总额：</span>
            <span class="value">{{item.zhAllprice}}</span>
          </div>

          <div class="content-item flex-50">
            <span class="label">满足天数：</span>
            <span class="value">{{item.loggrTxt}}</span>
          </div>
          <div class="content-item flex-50">
            <span class="label">优惠金额：</span>
            <span class="value">{{item.discountAllprice}}</span>
          </div>
          <div class="content-item flex-50">
            <span class="label">金额：</span>
            <span class="value">{{item.allPrice}}</span>
          </div>

          <!--        <Block :list="block.list" :config="block.config" :blockName="block.blockName"></Block>-->
        </div>
      </div>


      <div class="btn-container">
        <Button class="button" size="small" @click="orderDetailClick">查看订单详情</Button>
      </div>
      
    </div>
   
</template>

<script>
import {fetchOrderDetailBySap, fetchOrderLogisticsNotice, routerPath} from '@/api/order'
import {initMap, getZoom, playMapTraceLine, addMapMarkerCustom,addMapInfoWindow} from '@/utils/map';
import Header from '@/components/header/index.vue';
import Button from '@/components/common/button/CustomButton';



export default {
  components: {
    Header,
    Button
  },
  data() { 
    return {
      mapData:{
        center: {lng: 108.5525,lat:34.3277},
        zoom: 5,
        height: '80vh',
        startMarker: {
          icon: require('../../assets/images/map/start.png'),
        },

        carMarker: {
          show: true,
          icon: require('../../assets/images/map/car.png'),
        },
        endMarker: {
          show: false,
          icon: require('../../assets/images/map/end.png'),
          lng: '',
          lat: ''
        },
        carNumber: '陕YH0008',
        carNumberColor: '2',
        startRegionCode: '',
        endRegionCode: '',
        endAddress: '',
        sapOrderCode: '',
        pzCode: '',
        outTime: '2021-12-27 12:13:00',
        signTime: null,
        lineList:[{
          'lng': 116.297611,
          'lat': 40.047363
        }, {
          'lng': 116.302839,
          'lat': 40.048219
        }, {
          'lng': 116.308301,
          'lat': 40.050566
        }, {
          'lng': 116.305732,
          'lat': 40.054957
        }, {
          'lng': 116.304754,
          'lat': 40.057953
        }, {
          'lng': 116.306487,
          'lat': 40.058312
        },
          /* {
           'lng': 118.683787,
           'lat': 34.920526
         }*/
        ],
      },
      infoWindow: {
        show: false,
        showCar: false,
        showEnd: false,
        info:{
          factory: "未知",
          carNumber: "未知",
          outTime: "未知",
          driverName: "未知",
          driverPhone: "未知",
          address: "未知",
          arriveTime: "未知"
        },
      },
      orderInfoFlag: false,
      addDate: '',
      orderId: '',
      details: [],
      orderLogistics: null
    }
  },
  async mounted() {
    const res = await initMap('mapContainer');
    this.map = res.map;
    var sapOrderCode = this.$route.query.sapcode;
    var id = this.$route.query.id;
    let orderInfo = await fetchOrderDetailBySap({sapOrderCode: sapOrderCode,orderLogisticsId: id})
    this.infoWindow.info.factory = orderInfo.data.factoryName;
    this.mapData.endRegionCode =orderInfo.data.areaCode;
    this.mapData.endAddress = orderInfo.data.provinceName+orderInfo.data.cityName+orderInfo.data.areaName+orderInfo.data.tranAddress;
    this.infoWindow.info.endAddress = orderInfo.data.provinceName+orderInfo.data.cityName+orderInfo.data.areaName+orderInfo.data.tranAddress;
    this.addDate =orderInfo.data.addDate;
    this.orderId =orderInfo.data.rowId;
    this.details =orderInfo.data.details;
    this.orderLogistics =orderInfo.data.orderLogistics;
    this.orderInfoFlag = true;
    let logisticsInfo = await fetchOrderLogisticsNotice({id: id})

    if (undefined != logisticsInfo.data.signTime && null != logisticsInfo.data.signTime && '' != logisticsInfo.data.signTime) {
      this.mapData.carMarker.show = false;
    }
    
    this.mapData.carNumber = logisticsInfo.data.carNumber;
    this.infoWindow.info.carNumber = logisticsInfo.data.carNumber;
    this.mapData.outTime = logisticsInfo.data.outTime;
    this.mapData.signTime = logisticsInfo.data.signTime;
    this.mapData.startRegionCode = logisticsInfo.data.beginCounty;
    // this.mapData.endRegionCode = logisticsInfo.data.endCounty;
    // this.mapData.endAddress = logisticsInfo.data.endAddr;
    this.mapData.sapOrderCode = logisticsInfo.data.erpId;
    this.mapData.pzCode = logisticsInfo.data.pzCode;
    this.infoWindow.info.outTime = logisticsInfo.data.outTime;
    this.infoWindow.info.driverName = logisticsInfo.data.driverName;
    this.infoWindow.info.driverPhone = logisticsInfo.data.driverPhone;
    // this.infoWindow.info.endAddress = logisticsInfo.data.endAddr;
    this.infoWindow.info.signTime = logisticsInfo.data.signTime;


    this.handleMap();

    
    
    
  },
  methods: {
    async handleMap() {
      try {
        const response = await routerPath({
          vclN: this.mapData.carNumber,
          // vco: this.mapData.carNumberColor,
          vco: '2',
          qryBtm: this.mapData.outTime,
          qryEtm: this.mapData.signTime,
          startRegionCode: this.mapData.startRegionCode,
          endRegionCode: this.mapData.endRegionCode,
          endAddress: this.mapData.endAddress,
          sapOrderCode: this.mapData.sapOrderCode,
          pzCode: this.mapData.pzCode,

          // vclN: '陕YH0008',
          // vco: '2',
          // qryBtm: '2021-12-27 12:13:00',
          // endAddress: '山东省临沂市莒南县团林镇南唐楼',
        });
        if (response.code == 200) {
          if (undefined != response.data.startLng && null != response.data.startLng && '' != response.data.startLng &&
              undefined != response.data.endLat && null != response.data.endLat && '' != response.data.endLat) {
            this.mapData.endMarker.show = true;
            this.mapData.endMarker.lng = response.data.startLng;
            this.mapData.endMarker.lat = response.data.endLat;
          }

          this.mapData.lineList = [];
          var trackArray = response.data.trackArray;
          trackArray.forEach((item) => {
            this.mapData.lineList.push({
              lng: parseFloat(parseInt(item.lon)/600000).toFixed(5),
              lat: parseFloat(parseInt(item.lat)/600000).toFixed(5)
            });
          });
          if (this.mapData.carMarker.show) {
            this.infoWindow.info.address = response.data.transTimeManage.adr;
            this.infoWindow.info.arriveTime = response.data.transTimeManage.estimateArriveTime;
            //最后车辆位置
            this.mapData.lineList.push({
              lng: parseFloat(parseInt(response.data.transTimeManage.lon)/600000).toFixed(5),
              lat: parseFloat(parseInt(response.data.transTimeManage.lat)/600000).toFixed(5)
            });
          }


          //修改地图中心 以及比例
          var zoomObj = getZoom(this.mapData.lineList,this.map);
          this.mapData.center.lat = zoomObj.lat;
          this.mapData.center.lng = zoomObj.lng;
          this.mapData.zoom = zoomObj.zoom;
          const point = new window.BMapGL.Point(zoomObj.lng, zoomObj.lat);
          this.map.centerAndZoom(point, zoomObj.zoom);


          if (null != this.mapData.lineList && this.mapData.lineList.length > 0) {
            //画线
            playMapTraceLine(this.map, this.mapData.lineList);

            //画点 窗口
            var marker1 = addMapMarkerCustom({ map: this.map, latitude:this.mapData.lineList[0].lat, longitude:this.mapData.lineList[0].lng,icon: this.mapData.startMarker.icon})
            var content1 = "发货公司："+this.infoWindow.info.factory+"<br/>车牌号："+this.infoWindow.info.carNumber+"<br/>出厂时间："+this.infoWindow.info.outTime;
            content1 = content1.replace(/undefined/g,"");
            addMapInfoWindow(this.map,marker1,{
              latitude:this.mapData.lineList[0].lat,
              longitude:this.mapData.lineList[0].lng,
              content:content1
            });

            if (this.mapData.carMarker.show) {
              var marker2 = addMapMarkerCustom({ map: this.map, latitude:this.mapData.lineList[this.mapData.lineList.length-1].lat, longitude:this.mapData.lineList[this.mapData.lineList.length-1].lng,icon: this.mapData.carMarker.icon })
              var content2 = "承运人："+this.infoWindow.info.driverName+"("+this.infoWindow.info.driverPhone+")<br/>所在位置："+this.infoWindow.info.address+"<br/>预计到达时间："+this.infoWindow.info.arriveTime;
              content2 = content2.replace(/undefined/g,"");
              addMapInfoWindow(this.map,marker2,{
                latitude:this.mapData.lineList[this.mapData.lineList.length-1].lat,
                longitude:this.mapData.lineList[this.mapData.lineList.length-1].lng,
                content:content2
              });
            }

            var marker3 = addMapMarkerCustom({ map: this.map, latitude: this.mapData.endMarker.lat, longitude: this.mapData.endMarker.lng,icon: this.mapData.endMarker.icon })
            var content3 = "收货地址："+this.infoWindow.info.endAddress+"<br/>签收时间："+this.infoWindow.info.signTime;
            content3 = content3.replace(/undefined/g,"");
            addMapInfoWindow(this.map,marker3,{
              latitude:this.mapData.endMarker.lat,
              longitude:this.mapData.endMarker.lng,
              content:content3
            });

          }

          //this.mapData.lineList = response.data.trackArray;
        } else {
          // this.$toast(response.msg);
          this.$toast({
            duration: 30000, // 持续展示 toast
            position: 'top',
            message: response.msg,
          })
        }
      }  catch (err) {
        // this.$toast(err.message);
        this.$toast({
          duration: 30000, // 持续展示 toast
          position: 'top',
          message: err.message,
        })
      }
    },
    orderDetailClick() {
      this.$toast.clear();
      this.$router.push({path: `/order/detail?id=${this.orderId}&date=${this.addDate}&sapcode=${this.mapData.sapOrderCode}`});
    },

    callPhone(phoneNumber) {
      window.location.href = 'tel://' + phoneNumber
    },

    //根据原始数据计算中心坐标和缩放级别，并为地图设置中心坐标和缩放级别。
    /*setZoom(points){
      if(points.length>0){
        var maxLng = points[0].lng;
        var minLng = points[0].lng;
        var maxLat = points[0].lat;
        var minLat = points[0].lat;
        var res;
        for (var i = points.length - 1; i >= 0; i--) {
          res = points[i];
          if(res.lng > maxLng) maxLng =res.lng;
          if(res.lng < minLng) minLng =res.lng;
          if(res.lat > maxLat) maxLat =res.lat;
          if(res.lat < minLat) minLat =res.lat;
        }
        var cenLng =(parseFloat(maxLng)+parseFloat(minLng))/2;
        var cenLat = (parseFloat(maxLat)+parseFloat(minLat))/2;
        var zoom = this.getZoom(maxLng, minLng, maxLat, minLat);
        this.mapData.center.lat = cenLat;
        this.mapData.center.lng = cenLng;
        this.mapData.zoom = zoom;
      }else{
        //没有坐标，显示全中国
        this.mapData.center.lat = '35.563611';
        this.mapData.center.lng = '103.388611';
        this.mapData.zoom = '5';
      }
    },

    //根据经纬极值计算绽放级别。
    getZoom (maxLng, minLng, maxLat, minLat) {
      var zoom = ["50","100","200","500","1000","2000","5000","10000","20000","25000","50000","100000","200000","500000","1000000","2000000"];//级别18到3。
      var pointA = new window.BMap.Point(maxLng,maxLat);  // 创建点坐标A
      var pointB = new window.BMap.Point(minLng,minLat);  // 创建点坐标B
      var distance = new window.BMap.Map("container").getDistance(pointA,pointB).toFixed(1);  //获取两点距离,保留小数点后两位
      for (var i = 0,zoomLen = zoom.length; i < zoomLen; i++) {
        if(zoom[i] - distance > 0){
          return 18-i+3;//之所以会多3，是因为地图范围常常是比例尺距离的10倍以上。所以级别会增加3。
        }
      }
    },*/
    
    
  }
}
</script>

<style lang="scss" scoped>
.map-trace {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .map-container {
    width: 100%;
    height: 35%;
    flex: 1;
  }

  .order-info {
    height: 65vh;
    .title {
      position: relative;
      background: #ffffff;
      box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
      opacity: 1;
      border-radius: 10px;
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      padding: 12px;
      font-size: 14px;
      font-weight: 500;
      color: #2c2c2c;
    }

    .card-wrap {
      position: relative;
      background: #ffffff;
      box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
      opacity: 1;
      border-radius: 10px;
      padding: 12px;
      text-align: left;
      display: flex;
      flex-wrap: wrap;

      & > div {
        flex: 0 0 100%;
      }


      .content-item {
        display: flex;
        font-size: 14px;
        color: #2c2c2c;
        opacity: 1;
        margin-bottom: 8px;

        &.flex-50 {
          flex: 0 0 50%;
        }

        .label {
          color: #91939A;
          margin-left: 10px;
          flex-shrink: 0;
        }

        .value {
          color: #2C2C2C;
        }
      }


      .status-wrap {
        width: 120px;
        position: absolute;
        top: 13px;
        right: 10px;
        font-size: 14px;
        color: #2c2c2c;
        display: flex;
        white-space: nowrap;
      }
    }
  }

  .btn-container {
    position:fixed;
    bottom: 0px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 0px 5px;
    .button {
      width: 100%;
      height: 40px;
      font-size: 16px;
    }
  }
}
</style>
